import axios from 'commons/baseUrl'
import {
  CLEAR_COMPLIANCE,
  GET_COMPLIANCE_LIST_FAILED,
  GET_COMPLIANCE_LIST_REQUEST,
  GET_COMPLIANCE_LIST_SUCCESS,
  GET_COMPLIANCE_RETRY_LIST_FAILED,
  GET_COMPLIANCE_RETRY_LIST_REQUEST,
  GET_COMPLIANCE_RETRY_LIST_SUCCESS,
  UPDATE_COMPLIANCE_FAILED,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_SUCCESS,
} from 'constants/actionTypes/complianceActionTypes'
import { API_GET_COMPLIANCE_DATA, API_GET_COMPLIANCE_DATA_RETRY, API_UPDATE_COMPLIANCE_DATA } from 'constants/apiPath'
import { RESPONSE_TYPE } from 'constants/constant'

const getComplianceAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMPLIANCE_LIST_REQUEST })
    const res = await axios.get(API_GET_COMPLIANCE_DATA, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_COMPLIANCE_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data) {
      dispatch({ type: GET_COMPLIANCE_LIST_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: GET_COMPLIANCE_LIST_FAILED, payload: null })
  }
}
/*
const getComplianceActionRetry = (params) => async (dispatch) => {
  try {
    dispatch({ type: GET_COMPLIANCE_RETRY_LIST_REQUEST })
    const res = await axios.get(API_GET_COMPLIANCE_DATA_RETRY, { params })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: GET_COMPLIANCE_RETRY_LIST_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data) {
      dispatch({ type: GET_COMPLIANCE_RETRY_LIST_SUCCESS, payload: res?.data?.data })
    }
  } catch (err) {
    dispatch({ type: GET_COMPLIANCE_RETRY_LIST_FAILED, payload: null })
  }
}
*/
const getComplianceActionRetry = (params) => async (dispatch) => {
  const MAX_RETRIES = 10
  let retries = 0
  let totalItemCount = 0
  let allItems = []
  console.log('params:', params)
  while (retries < MAX_RETRIES) {
    let res
    try {
      dispatch({ type: GET_COMPLIANCE_RETRY_LIST_REQUEST })

      const responseParams = { ...params }
      if (params.ExclusiveStartKey) {
        responseParams.ExclusiveStartKey = params.ExclusiveStartKey
      }

      res = await axios.get(API_GET_COMPLIANCE_DATA_RETRY, { params: responseParams })

      if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
        dispatch({ type: GET_COMPLIANCE_RETRY_LIST_FAILED, payload: res.data.message })
        throw new Error(res.data.message || 'Unknown error')
      }

      if (res.data.STS === RESPONSE_TYPE.SUCCESS && res.data) {
        const items = res.data.data.data || []
        //console.log('Items retrieved:', items) // デバッグ用にitemsを出力
        const itemCount = items.length
        totalItemCount += itemCount

        allItems = allItems.concat(items)

        if (res.data.ExclusiveStartKey) {
          params.ExclusiveStartKey = res.data.ExclusiveStartKey
        } else {
          console.log(`Total items retrieved: ${totalItemCount}`)
          break
        }
      }
    } catch (err) {
      retries += 1
      if (retries >= MAX_RETRIES) {
        dispatch({ type: GET_COMPLIANCE_RETRY_LIST_FAILED, payload: err.message })
      } else {
        if (res && res.data && res.data.ExclusiveStartKey) {
          params.ExclusiveStartKey = res.data.ExclusiveStartKey
        } else {
          params.ExclusiveStartKey = null
        }
      }
    }
  }

  //console.log('All items before sorting:', allItems) // ソート前の全アイテムを確認

  // 取得したすべてのアイテムを並び替え
  allItems.sort((a, b) => {
    if (a.CD_CUST > b.CD_CUST) {
      return 1
    } else if (a.CD_CUST < b.CD_CUST) {
      return -1
    } else {
      if (a.CD_HAT > b.CD_HAT) {
        return 1
      } else if (a.CD_HAT < b.CD_HAT) {
        return -1
      } else {
        return 0
      }
    }
  })

  //console.log('Sorted items:', allItems) // ソート後のアイテムを確認

  // 並び替えたアイテムをdispatch
  dispatch({ type: GET_COMPLIANCE_RETRY_LIST_SUCCESS, payload: { data: allItems } })

  return allItems
}

const updateComplianceAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COMPLIANCE_REQUEST, payload: data })
    const res = await axios.post(API_UPDATE_COMPLIANCE_DATA, { data })
    if (!res.data || res.data.STS === RESPONSE_TYPE.ERROR) {
      dispatch({ type: UPDATE_COMPLIANCE_FAILED, payload: res.data.message })
      throw new Error()
    }
    if (res.data.STS === RESPONSE_TYPE.DATA_NOT_EXIST) {
      dispatch({ type: UPDATE_COMPLIANCE_FAILED, payload: res.data.message })
    }
    if (res.data.STS === RESPONSE_TYPE.SUCCESS) {
      dispatch({ type: UPDATE_COMPLIANCE_SUCCESS, payload: res.data.data })
    }
  } catch (err) {
    dispatch({ type: UPDATE_COMPLIANCE_FAILED, payload: err.message })
  }
}

const clearCompliance = (data) => async (dispatch) => {
  dispatch({ type: CLEAR_COMPLIANCE, payload: '' })
}

export { clearCompliance, getComplianceAction, getComplianceActionRetry, updateComplianceAction }
