import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'

import styles from './CustomTooltipStyles'

const CustomTooltip = ({ title, children, ...rest }) => {
  const classes = styles()
  return (
    <Tooltip
      title={title}
      className={`${classes.root} arrow_box`}
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
