import './Compliance.css'

import {
  Button,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { getAllergenAction } from 'actions/allergenActions'
import getCodeInfo from 'actions/codeActions'
import {
  clearCompliance,
  getComplianceAction,
  getComplianceActionRetry,
  updateComplianceAction,
} from 'actions/complianceActions'
// import axios from 'commons/baseUrl'
// import Checkbox from '@material-ui/core/Checkbox'
import {
  createListDtWeek,
  formatDateWithPadding,
  getSessionInfo,
  getTitleModalConfirm,
  getUserKbAdmin,
  getWeekOfMonth,
  handleDownload2,
} from 'commons/utilities'
import { CustomCheckbox, ModalConfirmNavigation } from 'components/commons'
import { MessageErrorDialog, ModalConfirm, ModalConfirm as UpdateResult, ProgressDialog } from 'components/commons'
import ToolBar from 'components/commons/ToolBar/ToolBar'
import {
  GET_ALLERGEN_LIST_FAILED,
  GET_ALLERGEN_LIST_REQUEST,
  GET_ALLERGEN_LIST_SUCCESS,
} from 'constants/actionTypes/allergenActionTypes'
import {
  GET_COMPLIANCE_LIST_FAILED,
  GET_COMPLIANCE_LIST_REQUEST,
  GET_COMPLIANCE_LIST_SUCCESS,
  GET_COMPLIANCE_RETRY_LIST_FAILED,
  GET_COMPLIANCE_RETRY_LIST_REQUEST,
  GET_COMPLIANCE_RETRY_LIST_SUCCESS,
  UPDATE_COMPLIANCE_FAILED,
  UPDATE_COMPLIANCE_REQUEST,
  UPDATE_COMPLIANCE_SUCCESS,
} from 'constants/actionTypes/complianceActionTypes'
import {
  ALLERGEN_CLASSFICATION_CODE,
  CD_KEY,
  FLAG_BOOLEAN,
  KONTAMI_CLASSFICATION_CODE,
  TOOLBAR_TYPE,
} from 'constants/constant'
import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../../components/commons/Table/TableCommonStyle'

function Compliance() {
  const sysInfo = useSelector((state) => {
    if (state.systemInfo && state.systemInfo.system) return state.systemInfo.system
    return {}
  })

  const userInfo = useSelector((state) => {
    if (state.userInfo && state.userInfo.userInfo && state.userInfo.userInfo.data) return state.userInfo.userInfo.data
    return {}
  })
  const classes = styles()
  const RedRadio = withStyles({
    root: {
      color: red[400],
      '&$checked': {
        color: red[600],
      },
    },
    checked: {},
  })((props) => (
    <Radio
      color="default"
      checked={false}
      value="a"
      name="radio-button"
      inputProps={{ 'aria-label': 'A' }}
      size="small"
      {...props}
    />
  ))
  const DT_TODAY = sysInfo?.DT_TODAY
    ? formatDateWithPadding(sysInfo?.DT_TODAY, 'yyyy-MM-dd')
    : formatDateWithPadding(new Date(), 'yyyy-MM-dd')
  const { i18n, t } = useTranslation()

  const dispatch = useDispatch()
  const [searchInput, setSearchInput] = useState('')
  const [confirmedSearchInput, setconfirmedSearchInput] = useState('')
  const [approvalItem, setApprovalItem] = useState(FLAG_BOOLEAN.FALSE)
  const [hqConfirmItem, setHqConfirmItem] = useState(FLAG_BOOLEAN.FALSE)
  const [contaminationDisplay, setContaminationDisplay] = useState(FLAG_BOOLEAN.FALSE)
  const codeInfo = useSelector((state) => state.codeInfo.code || {})
  const listComplianceReducer = useSelector((state) => state.listCompliance || {})
  const listComplianceUpdate = useSelector((state) => state.updateCompliance || {})
  const listComplianceRetryReducer = useSelector((state) => state.listComplianceRetry || {})

  const [messageError, setMessageError] = useState()
  const [loading, setLoading] = useState(false)
  const [listData, setListData] = useState([])
  const [listAllergen, setListAllergen] = useState([])
  const [uniqueSupplierList, setUniqueSupplierList] = useState([])
  const listAllergenReducer = useSelector((state) => state.listAllergen || {})
  const [complianceKbEdit, setComplianceKbEdit] = useState(FLAG_BOOLEAN.FALSE)
  const [showModal, setShowModal] = useState(false)
  const [showModalUpdateResult, setShowModalUpdateResult] = useState(false)
  const [showModalConfirmAction, setShowModalConfirmAction] = useState(false)

  const [approvalListItems, setApprovalListItems] = useState([])
  const [hqConfirmListItems, setHqConfirmListItems] = useState([])
  const [dataCompliance, setDataCompliance] = useState([])
  // const [isUpdated, setIsUpdated] = useState(false)
  const [onSearch, setOnSearch] = useState(false)
  const [onWeekFilter, setOnWeekFilter] = useState(false)
  const [onKbEdit, setOnKbEdit] = useState(false)
  const [valueKbEdit, setValueKbEdit] = useState(false)
  const [onEditApproval, setOnEditApproval] = useState(false)
  const [onEditHqItem, setOnEditHqItem] = useState(false)
  const [onDowloadCsv, setOnDowloadCsv] = useState(false)
  const [valueApproval, setValueApproval] = useState(FLAG_BOOLEAN.FALSE)
  const [valueHqItem, setValueHqItem] = useState(FLAG_BOOLEAN.FALSE)
  const [isBlocked, setIsBlocked] = useState(false)
  const [updatePermissionMessageError, setUpdatePermissionMessageError] = useState('')

  // const dateRef = useRef()
  const week = getWeekOfMonth(DT_TODAY)
  const [weekValue, setWeekValue] = useState(week)
  const temp1 = new Date(DT_TODAY).getUTCMonth() + 1
  const temp2 = new Date(DT_TODAY).getUTCFullYear()

  const currentMonth = String(temp1).length < 2 ? `0${temp1}` : `${temp1}`
  const currentYear = String(temp2)

  const currentDtWeek = currentYear + currentMonth + week

  // const getInitialItemDtWeekValue = (year) => {
  //   const initialFilterItemDtWeek = listYear.filter((el) => {
  //     return el.label === year
  //   })
  //   return initialFilterItemYear[0]?.value
  // }

  const [filterItemDtWeek, setFilterItemDtWeek] = useState('0')

  const [listDtWeek, setListDtWeek] = useState([])

  const [listDtWeekOption, setListDtWeekOption] = useState([])

  const [listDefaultUpdateCompliance, setListDefaultUpdateCompliance] = useState([])

  const updated = useRef()

  const dtWeekRef = useRef()
  // const [type, setType] = useState('')

  // const TYPE = {
  //   CONFIRM_UPDATE: 'CONFIRM_UPDATE',
  //   UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  //   UPDATE_FAILED: 'UPDATE_FAILED',
  // }

  //const filteredFields = ['CD_HAT', 'NM_HAT', 'CD_SYO', 'NM_SYO', 'CD_CAT', 'CD_PREF']

  const APPROVE_ALL_OPTION = '2'

  useEffect(() => {
    updated.current = false
  }, [])

  useEffect(() => {
    if (listData.length > 0) {
      const dataResult = []
      listData.forEach((data) => {
        dataResult.push({
          ...data,
          KB_CNFM: data.hasOwnProperty('KB_CNFM') ? data.KB_CNFM : FLAG_BOOLEAN.FALSE,
          DT_CNFM: formatDateWithPadding(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS'),
          DT_UPDATE: formatDateWithPadding(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS'),
        })
      })
      setDataCompliance(dataResult)
    }
  }, [listData])

  useEffect(() => {
    if (Object.keys(sysInfo).length > 0) {
      setContaminationDisplay(sysInfo.MP_COMP?.KB_CONTAMI || FLAG_BOOLEAN.FALSE)
    }
  }, [sysInfo])

  useEffect(() => {
    //if (listComplianceReducer?.listCompliance) {
    if (listComplianceReducer?.listCompliance?.listDtWeek) {
      const listDTWeek = listComplianceReducer.listCompliance.listDtWeek
      setListDtWeek(listDTWeek)
      setListDtWeekOption(createListDtWeek(listDTWeek, currentDtWeek))
      updateDtWeekRef()
    }
    //}
    //console.log('listComplianceRetryReducer: ', listComplianceRetryReducer?.listComplianceRetry)
    if (listComplianceRetryReducer?.listComplianceRetry) {
      let listdataReducer = listComplianceRetryReducer?.listComplianceRetry?.data || []
      let dataResponse = []
      //console.log('listdataReducer: ', listdataReducer)
      if (listdataReducer) {
        dataResponse = listdataReducer?.map((data) => ({
          ...data,
          KB_CNFM: data.hasOwnProperty('KB_CNFM') ? data.KB_CNFM : FLAG_BOOLEAN.FALSE,
          KB_APPR: data.hasOwnProperty('KB_APPR') ? data.KB_APPR : FLAG_BOOLEAN.FALSE,
        }))
        console.log('dataResponse:', dataResponse)
        setListData([...dataResponse])
      }
    }
    if (listAllergenReducer.listAllergen.length >= 0) {
      setListAllergen([...listAllergenReducer.listAllergen])
    }

    setLoading(
      listComplianceRetryReducer.loading ||
        listAllergenReducer.loading ||
        listComplianceUpdate.loading ||
        listComplianceReducer.loading
    )

    switch (listComplianceReducer.type) {
      case GET_COMPLIANCE_LIST_REQUEST:
        break
      case GET_COMPLIANCE_LIST_FAILED:
        if (!getSessionInfo()) {
          listComplianceReducer.message && setMessageError(listComplianceReducer.message)
        }
        break
      case GET_COMPLIANCE_LIST_SUCCESS:
        break
      default:
        return
    }

    switch (listComplianceRetryReducer.type) {
      case GET_COMPLIANCE_RETRY_LIST_REQUEST:
        break
      case GET_COMPLIANCE_RETRY_LIST_FAILED:
        if (!getSessionInfo()) {
          listComplianceRetryReducer.message && setMessageError(listComplianceRetryReducer.message)
        }
        break
      case GET_COMPLIANCE_RETRY_LIST_SUCCESS:
        break
      default:
        return
    }

    switch (listAllergenReducer.type) {
      case GET_ALLERGEN_LIST_REQUEST:
        break
      case GET_ALLERGEN_LIST_FAILED:
        if (!getSessionInfo()) {
          listComplianceReducer.message && setMessageError(listComplianceReducer.message)
        }
        break
      case GET_ALLERGEN_LIST_SUCCESS:
        break
      default:
        return
    }

    switch (listComplianceUpdate.type) {
      case UPDATE_COMPLIANCE_REQUEST:
        break
      case UPDATE_COMPLIANCE_FAILED:
        if (!getSessionInfo()) {
          setMessageError(listComplianceUpdate.message)
        }
        break
      case UPDATE_COMPLIANCE_SUCCESS:
        //dispatch(getComplianceAction({ week: dtWeekRef.current }))
        dispatch(
          getComplianceActionRetry({
            week: dtWeekRef.current,
            kb_edit: complianceKbEdit,
            kb_appr: approvalItem,
            kb_cnfm: hqConfirmItem,
            cd_cust: confirmedSearchInput,
          })
        )
        setShowModalUpdateResult(true)
        dispatch(clearCompliance())
        break
      default:
        break
    }
  }, [listComplianceReducer, listComplianceRetryReducer, listAllergenReducer, listComplianceUpdate])

  useEffect(() => {
    updateDtWeekRef()
    dispatch(getCodeInfo())
    dispatch(getComplianceAction({}))
    /*
    dispatch(
      getComplianceActionRetry({
        kb_edit: valueKbEdit,
        kb_appr: approvalItem,
        kb_cnfm: valueHqItem,
        cd_cust: searchInput,
      })
    )
    */
    dispatch(getAllergenAction())
  }, [dispatch])

  const getOptionsByCdKey = (key) => {
    const codeFilter = codeInfo.filter((el) => el.CD_KEY === key)
    if (!codeFilter || !codeFilter.length) {
      return []
    }

    const items = []
    Object.values(codeFilter[0]).forEach((element, index) => {
      if (typeof element === 'object') {
        items.push({
          value: element.CD_VALUE || index,
          label: element.NM_VALUE || index,
        })
      }
    })

    return items
  }

  useEffect(() => {
    if (Object.keys(codeInfo).length) {
      setApprovalListItems(getOptionsByCdKey(CD_KEY.APRVL))
      setHqConfirmListItems(getOptionsByCdKey(CD_KEY.CNFM))
    }
  }, [codeInfo])

  const fetchData = async () => {
    try {
      await dispatch(
        getComplianceActionRetry({
          week: dtWeekRef.current,
          kb_edit: complianceKbEdit,
          kb_appr: approvalItem,
          kb_cnfm: hqConfirmItem,
          cd_cust: confirmedSearchInput,
        })
      )
    } catch (error) {
      console.error('Failed to fetch data:', error)
      // エラーハンドリングをここに追加
    }
  }
  useEffect(() => {
    fetchData(valueApproval)
  }, [approvalItem, hqConfirmItem, complianceKbEdit, confirmedSearchInput])

  const getDtWeekLabel = async () => {
    const listDtWeekFiltered = listDtWeekOption.filter((dtWeek) => dtWeek.value === String(filterItemDtWeek))
    const dtWeekLabel = listDtWeekFiltered[0]?.label
    return dtWeekLabel
  }

  const updateDtWeekRef = async () => {
    dtWeekRef.current = await getDtWeekLabel()
  }

  useEffect(() => {
    updateDtWeekRef()
  }, [filterItemDtWeek])
  /*
  const handleFilterDate = (e) => {
    e.preventDefault()
    setTimeout(() => {
      console.log('Test1')
      //dispatch(getComplianceAction({ week: dtWeekRef.current }))
      dispatch(getComplianceActionRetry({ week: dtWeekRef.current }))
    }, 10)
  }
  */
  const handleFilterDate = async (e) => {
    e.preventDefault()
    await updateDtWeekRef()
    // dtWeekRefの値をログに出力して確認
    console.log('Current dtWeekRef:', dtWeekRef.current)
    try {
      await dispatch(
        getComplianceActionRetry({
          week: dtWeekRef.current,
          kb_edit: complianceKbEdit,
          kb_appr: approvalItem,
          kb_cnfm: hqConfirmItem,
          cd_cust: confirmedSearchInput,
        })
      )
      console.log('Data fetched successfully.')
      // データ取得後に後続処理をここに追加
    } catch (error) {
      console.error('Failed to fetch data:', error)
      // エラーハンドリングをここに追加
    }
  }
  const handleSearchInput = (event) => {
    setOnSearch(true)
    event.preventDefault()
    setconfirmedSearchInput(searchInput)
    if (updated.current) {
      setShowModalConfirmAction(true)
    }
  }

  const listToolBarComponent = [
    { type: TOOLBAR_TYPE.FILTER_LOGO, commonStyle: 'filterWeekContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.WEEK_CALENDAR, commonStyle: 'complianceCalendarFilter', componentStyle: 'calendar-item' },
    { type: TOOLBAR_TYPE.SEARCH, commonStyle: 'searchContainer', componentStyle: 'search-item' },
    { type: TOOLBAR_TYPE.COMPLIANCE_CHECKBOX, commonStyle: '', componentStyle: '' },
    { type: TOOLBAR_TYPE.APPROVAL_SELECT, commonStyle: 'approvalContainer', componentStyle: 'approval-select' },
    { type: TOOLBAR_TYPE.HEAD_QUARTERS_CONFIRM_SELECT, commonStyle: 'hqConfirmContainer', componentStyle: '' },
    { type: TOOLBAR_TYPE.CSV, commonStyle: '', componentStyle: '' },
  ]

  const saveDefaultStateUpdatedCompliance = (data = []) => {
    const prevDefaultCompliance = [...listDefaultUpdateCompliance]
    if (
      !prevDefaultCompliance.find(
        (compliance) => compliance?.CD_CUST === data?.CD_CUST && compliance?.KY_SORT === data?.KY_SORT
      )
    ) {
      prevDefaultCompliance.push({
        CD_CUST: data?.CD_CUST,
        KY_SORT: data?.KY_SORT,
        KB_CNFM: data?.KB_CNFM,
      })
    }
    setListDefaultUpdateCompliance(prevDefaultCompliance)
  }

  const handleUpdateCompliance = async (name, value, data) => {
    if (name === 'KB_EDIT') {
      setOnKbEdit(true)
      setValueKbEdit(value)
      setconfirmedSearchInput(searchInput)
      if (updated.current) {
        setShowModalConfirmAction(true)
      } else {
        setComplianceKbEdit((value + 0).toString())
      }
    }
    if (data?.hasOwnProperty('DT_APPR') && data.DT_APPR !== '') {
      if (name === 'KB_CNFM') {
        saveDefaultStateUpdatedCompliance(data)

        const modifiedDataIndex = dataCompliance.findIndex(
          (el) => el.CD_CUST === data.CD_CUST && el.KY_SORT === data.KY_SORT
        )

        const modifedData = [...dataCompliance]
        modifedData[modifiedDataIndex] = {
          ...modifedData[modifiedDataIndex],
          KB_CNFM: value === true ? FLAG_BOOLEAN.TRUE : FLAG_BOOLEAN.FALSE,
          DT_CNFM: formatDateWithPadding(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS'),
          DT_UPDATE: formatDateWithPadding(new Date(), 'yyyy-MM-dd HH:mm:ss:SSS'),
          ID_HQ: userInfo?.NM_USER || '',
          UPDATED: true,
        }
        updated.current = true
        setDataCompliance(modifedData)
        setIsBlocked(true)
      }
    }
  }

  const findIndexData = (data) => {
    if (dataCompliance.length > 0) {
      return dataCompliance.findIndex((el) => el.CD_CUST === data.CD_CUST && el.KY_SORT === data.KY_SORT)
    }
    return FLAG_BOOLEAN.FALSE
  }

  let setDownloadParams = {
    screenName: 'compliance',
    searchText: confirmedSearchInput,
    week: dtWeekRef.current,
    approval: approvalItem,
    hqConfirm: hqConfirmItem,
    kbEdit: complianceKbEdit,
  }

  const handleUpdateApproval = (value) => {
    setOnEditApproval(true)
    setValueApproval(value)
    setconfirmedSearchInput(searchInput)
    if (updated.current) {
      setShowModalConfirmAction(true)
    } else {
      setApprovalItem(value)
    }
  }

  const handleUpdateHqConfirm = (value) => {
    setOnEditHqItem(true)
    setValueHqItem(value)
    setconfirmedSearchInput(searchInput)
    if (updated.current) {
      setShowModalConfirmAction(true)
    } else {
      setHqConfirmItem(value)
    }
  }

  const handleDowloadCsv = () => {
    setOnDowloadCsv(true)
    if (updated.current) {
      setShowModalConfirmAction(true)
    } else {
      handleDownload2(setDownloadParams, i18n)
    }
  }

  const toolbar = (
    <ToolBar
      listToolBarComponent={listToolBarComponent}
      filterLabel={t('compliance.lblWeekFilter')}
      handleFilterDate={handleFilterDate}
      setSearchInput={setSearchInput}
      searchInput={searchInput}
      handleSubmit={handleSearchInput}
      approvalItem={approvalItem}
      hqConfirmItem={hqConfirmItem}
      handleUpdateApproval={handleUpdateApproval}
      handleUpdateHqConfirm={handleUpdateHqConfirm}
      approvalListItems={approvalListItems}
      hqConfirmListItems={hqConfirmListItems}
      handleUpdateCompliance={handleUpdateCompliance}
      complianceKbEdit={complianceKbEdit}
      // downloadParams={setDownloadParams}
      // weekInputs={true}
      setWeekValue={setWeekValue}
      weekValue={weekValue}
      listDtWeek={listDtWeek}
      listDtWeekOption={listDtWeekOption}
      setFilterItemDtWeek={setFilterItemDtWeek}
      filterItemDtWeek={filterItemDtWeek}
      handleDowloadCsv={handleDowloadCsv}
      screen={t('compliance.lblScreenName')}
    />
  )
  /*
  const isSupplierGroupEmpty = (code) => {
    return listData.some(
      (data) =>
        data.CD_HAT === code &&
        data.KB_CNFM === hqConfirmItem &&
        (approvalItem === APPROVE_ALL_OPTION || data.KB_APPR === approvalItem) &&
        (complianceKbEdit === FLAG_BOOLEAN.TRUE ? data.KB_EDIT === complianceKbEdit : true)
    )
  }
  */
  useEffect(() => {
    const isSupplierGroupEmpty = (code) => {
      return listData.some(
        (data) =>
          data.CD_HAT === code &&
          data.KB_CNFM === hqConfirmItem &&
          (approvalItem === APPROVE_ALL_OPTION || data.KB_APPR === approvalItem) &&
          (complianceKbEdit === FLAG_BOOLEAN.TRUE ? data.KB_EDIT === complianceKbEdit : true)
      )
    }
    if (listData.length >= 0) {
      // Find all unquie supplier code from data response
      let supplierListArr = []
      let uniqueSupplierCode = [...new Set(listData.map((item) => item.CD_HAT))].filter((code) =>
        isSupplierGroupEmpty(code)
      )
      uniqueSupplierCode.forEach((supplierCode) => {
        supplierListArr.push(listData.find((data) => data.CD_HAT === supplierCode))
      })

      setUniqueSupplierList(supplierListArr)
    }
  }, [listData, hqConfirmItem, approvalItem, complianceKbEdit])

  /**
   * Delete code being disable
   * @return {Void}
   */
  const deleteDisableCode = (allrgenCodeEdit, attribute) => {
    Object.keys(allrgenCodeEdit).forEach((code) => {
      if (allrgenCodeEdit[code]['BF'] === allrgenCodeEdit[code]['AF']) {
        if (allrgenCodeEdit[code][`${attribute}`] === FLAG_BOOLEAN.FALSE) {
          delete allrgenCodeEdit[code]
        }
      }
    })
  }

  /**
   * Get Allergen Name List base on allergen code
   * @return {String}
   */
  const getAllergenName = (data, returnType = '', type = '') => {
    let itemCount = 1
    let listAllergenText = ''
    let target = {}
    const allergenFoundArr = []

    const allrgenCodeEdit = Object.assign(target, type === 'kontami' ? data.CD_CNTM : data.CD_ARG)

    if (complianceKbEdit === FLAG_BOOLEAN.TRUE) {
      deleteDisableCode(allrgenCodeEdit, 'AF')
    }
    if (complianceKbEdit === FLAG_BOOLEAN.FALSE) {
      if (data.KB_EDIT === FLAG_BOOLEAN.TRUE) {
        deleteDisableCode(allrgenCodeEdit, 'AF')
      } else {
        deleteDisableCode(allrgenCodeEdit, 'BF')
      }
    }

    Object.keys(allrgenCodeEdit).forEach((code) => {
      let allergenFound = listAllergen.find((allergen) => allergen.CD_ALRG === code)
      if (typeof allergenFound !== 'undefined') {
        listAllergenText += itemCount + ' : ' + allergenFound.NM_ALRG + ', '

        allergenFoundArr.push({
          name: allergenFound.NM_ALRG,
          code: allergenFound.CD_ALRG,
          redColor:
            allrgenCodeEdit[allergenFound.CD_ALRG].AF === FLAG_BOOLEAN.TRUE &&
            allrgenCodeEdit[allergenFound.CD_ALRG].BF === FLAG_BOOLEAN.FALSE
              ? true
              : false,
          DelColor:
            allrgenCodeEdit[allergenFound.CD_ALRG].AF === FLAG_BOOLEAN.FALSE &&
            allrgenCodeEdit[allergenFound.CD_ALRG].BF === FLAG_BOOLEAN.TRUE
              ? true
              : false,
        })
      }
      itemCount++
    })

    if (returnType === 'html') return renderListAllergenHTml(allergenFoundArr)
    return listAllergenText.substring(0, listAllergenText.length - 2)
  }

  const renderListAllergenHTml = (arr = []) => {
    arr = arr.sort((a, b) => a.code.localeCompare(b.code))
    return (
      <div>
        {arr.map((data, index) => {
          return (
            <>
              <span className={data.DelColor ? 'back-red' : data.redColor ? 'text-red' : ''}>{`${data.code} : `}</span>
              <span className={data.DelColor ? 'back-red' : data.redColor ? 'text-red' : ''}>{`${data.name} `}</span>
              <span>{`${index === arr.length - 1 ? '' : ', '} `}</span>
            </>
          )
        })}
      </div>
    )
  }

  const getClassficationCode = (isTextRed = false, text = '') => {
    return <span className={`${isTextRed ? 'text-red' : ''}`}>{text}</span>
  }

  /**
   * Return Allergen Text base on allergen code
   * @return {String}
   */
  const renderAllrgenData = (data) => {
    let condition = data.KB_ARGSET?.AF !== data.KB_ARGSET?.BF ? true : false
    switch (data.KB_ARGSET?.AF) {
      case ALLERGEN_CLASSFICATION_CODE.NOT_SET:
        return getClassficationCode(condition, t('compliance.lblCodeAllergenNotSet'))

      case ALLERGEN_CLASSFICATION_CODE.HAVE_ALLERGEN:
        return getAllergenName(data, 'html')
      case ALLERGEN_CLASSFICATION_CODE.NOT_APPLY:
        return getClassficationCode(condition, t('compliance.lblCodeAllergenNoApply'))

      case ALLERGEN_CLASSFICATION_CODE.NO_ALLERGEN:
        return getClassficationCode(condition, t('compliance.lblCodeNotHaveAllergen'))

      default:
        return
    }
  }

  /**
   * Return Kontami Text base on kontami code
   * @return {String}
   */
  const renderKontamiData = (data) => {
    let condition = data.KB_CNTMSET?.AF !== data.KB_CNTMSET?.BF ? true : false
    switch (data.KB_CNTMSET?.AF) {
      case KONTAMI_CLASSFICATION_CODE.NOT_SET:
        return getClassficationCode(condition, t('compliance.lblCodeKontamiNotSet'))

      case KONTAMI_CLASSFICATION_CODE.HAVE_KONTAMI:
        return getAllergenName(data, 'html', 'kontami')

      case KONTAMI_CLASSFICATION_CODE.NOT_APPLY:
        return getClassficationCode(condition, t('compliance.lblCodeKontamiNoApply'))

      case KONTAMI_CLASSFICATION_CODE.NO_KONTAMI:
        return getClassficationCode(condition, t('compliance.lblCodeNotHaveKontami'))

      default:
        return
    }
  }

  /**
   * Handle Update
   * @return {Void}
   */
  const handleSubmit = async (e) => {
    e.preventDefault()
    const kbAdmin = await getUserKbAdmin(userInfo)
    if (!kbAdmin) {
      setUpdatePermissionMessageError(t('commonComponent.noUpdatePermission'))
    } else {
      setShowModal(true)
    }
  }

  /**
   * Handle Submited Update
   * @return {Void}
   */
  const submitData = () => {
    const updatedData = dataCompliance.filter((data) => data.hasOwnProperty('UPDATED'))
    dispatch(updateComplianceAction(updatedData))
    setShowModal(false)
    setIsBlocked(false)
    updated.current = false
  }

  const handleCloseUpdateModal = () => {
    setShowModalUpdateResult(false)
    // setHqConfirmItem(FLAG_BOOLEAN.FALSE)
    // setApprovalItem(FLAG_BOOLEAN.FALSE)
    // setComplianceKbEdit(FLAG_BOOLEAN.TRUE)
    // setSearchInput('')
    //dispatch(getComplianceAction({ week: dtWeekRef.current }))
    dispatch(
      getComplianceActionRetry({
        week: dtWeekRef.current,
        kb_edit: complianceKbEdit,
        kb_appr: approvalItem,
        kb_cnfm: hqConfirmItem,
        cd_cust: confirmedSearchInput,
      })
    )
  }

  const resetDefaultStateCompliance = () => {
    const modifedData = [...dataCompliance]
    let modifiedDataIndex = 0

    listDefaultUpdateCompliance.forEach((compliance) => {
      modifiedDataIndex = dataCompliance.findIndex(
        (el) => el.CD_CUST === compliance.CD_CUST && el.KY_SORT === compliance.KY_SORT
      )

      modifedData[modifiedDataIndex] = {
        ...modifedData[modifiedDataIndex],
        KB_CNFM: compliance.KB_CNFM,
      }
    })

    setDataCompliance(modifedData)
    setListDefaultUpdateCompliance([])
  }

  /**
   * Handle Submited Navigated Action
   * @return {Void}
   */
  const submitConfirmAction = () => {
    resetDefaultStateCompliance()

    updated.current = false
    setShowModalConfirmAction(false)
    setIsBlocked(false)
    // Submit Searching Action
    //dispatch(getComplianceAction({ week: dtWeekRef.current }))
    // Submit Week Filter Action
    if (onWeekFilter) {
      //dispatch(getComplianceAction({ week: dtWeekRef.current }))
      dispatch(
        getComplianceActionRetry({
          week: dtWeekRef.current,
          kb_edit: complianceKbEdit,
          kb_appr: approvalItem,
          kb_cnfm: hqConfirmItem,
          cd_cust: confirmedSearchInput,
        })
      )
      setOnWeekFilter(false)
      return
    }
    if (onSearch) {
      setListData([...listData])
      setOnSearch(false)
      return
    }

    // Kb Edit Checkbox
    if (onKbEdit) {
      setComplianceKbEdit((valueKbEdit + 0).toString())
      setOnKbEdit(false)
    }
    // Approval edit
    if (onEditApproval) {
      setApprovalItem(valueApproval)
      setOnEditApproval(false)
    }
    // Hq confim  edit
    if (onEditHqItem) {
      setHqConfirmItem(valueHqItem)
      setOnEditHqItem(false)
    }
    // Dowload Csv
    if (onDowloadCsv) {
      handleDownload2(setDownloadParams, i18n)
      setOnDowloadCsv(false)
    }
  }

  const messageNavigateConfirm = `<div class="message__pre-text"><div>
  ${t('compliance.lblNavigateConfirmLine1')} </div><div>
  ${t('compliance.lblNavigateConfirmLine2')}</div></div>`

  const messageActionConfirm = `<div class="message__pre-text"><div>
  ${t('compliance.lblActionConfirmLine1')} </div><div>
  ${t('compliance.lblActionConfirmLine2')}</div></div>`

  return (
    <div className="compliance__wrapper">
      <UpdateResult
        title={t('compliance.lblUpdateSuccess')}
        showModal={showModalUpdateResult}
        setShowModal={setShowModalUpdateResult}
        screen={'compliance'}
        handleFunction={handleCloseUpdateModal}
      />
      {/* Modal confirm update */}

      <ModalConfirm
        title={getTitleModalConfirm(t('compliance.lblConfirmUpdate.line1'), t('compliance.lblConfirmUpdate.line2'))}
        showModal={showModal}
        setShowModal={setShowModal}
        handleFunction={submitData}
      />
      {/* Modal confirm action */}
      <ModalConfirm
        title={messageActionConfirm}
        showModal={showModalConfirmAction}
        setShowModal={setShowModalConfirmAction}
        handleFunction={submitConfirmAction}
      />
      {<ModalConfirmNavigation title={messageNavigateConfirm} isBlocked={isBlocked} />}
      {messageError && <MessageErrorDialog message={messageError} showMessageErrorDialog={setMessageError} />}
      {updatePermissionMessageError && (
        <MessageErrorDialog
          message={updatePermissionMessageError}
          showMessageErrorDialog={setUpdatePermissionMessageError}
        />
      )}
      {loading ? (
        <ProgressDialog label={t('excelTemplate.loading')} />
      ) : (
        <div className="compliance__container maintain-container ">
          <div className="table-container">
            <Grid item md={12}>
              <TableContainer className="table-compliance-padding">
                <div className={`${uniqueSupplierList.length > 0 ? 'table-scroll' : ''}`}>
                  {/* Toolbar */}
                  {toolbar}
                  {/* Table */}
                  <div className="compliance-content">
                    <Table aria-label="common table" className={classes.fullWidthTable}>
                      <colgroup>
                        <col style={{ width: '5.17%' }} className="compliance-header-col-1" />
                        <col style={{ width: '13%' }} />
                        <col style={{ width: '58.5%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                      </colgroup>
                      <TableHead className={classes.tableHead}>
                        <TableRow>
                          <TableCell className={`${classes.titleHeadCompliance} ${classes.textCenter}`}>
                            {t('compliance.lblConfirm')}
                          </TableCell>
                          <TableCell className={classes.titleHeadCompliance}>
                            {t('compliance.lblProductCode')}
                          </TableCell>
                          <TableCell className={classes.titleHeadCompliance} style={{ width: '500px' }}>
                            {t('compliance.lblProductName')}
                          </TableCell>
                          <TableCell className={classes.titleHeadCompliance}>
                            {t('compliance.lblClassification')}
                          </TableCell>
                          <TableCell className={`${classes.titleHeadCompliance} ${classes.approvalDate}`}>
                            {t('compliance.lblApprovalDate')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className={`${classes.titleHeadCompliance} ${classes.textCenter}`}>
                            {t('compliance.lblConfirmDate')}
                          </TableCell>
                          <TableCell className={`${classes.titleHeadCompliance} ${classes.productTradeMarkContainer}`}>
                            <div style={{ display: 'flex' }}>
                              <Grid className={classes.tradeMark}>
                                {sysInfo?.MP_COMP?.KB_SYOHYO === FLAG_BOOLEAN.TRUE ? t('compliance.lblTradeMark') : ''}
                              </Grid>
                              <Grid className={classes.planConfirm}>
                                {sysInfo?.MP_COMP?.KB_KANNAI === FLAG_BOOLEAN.TRUE
                                  ? t('compliance.lblPlanConfirm')
                                  : ''}
                              </Grid>
                            </div>
                          </TableCell>
                          <TableCell className={classes.titleHeadCompliance}>
                            <div>{t('compliance.lblAllergen')}</div>
                            {sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.FALSE ? (
                              ''
                            ) : (
                              <div>{t('compliance.lblContami')}</div>
                            )}
                          </TableCell>
                          <TableCell className={classes.titleHeadCompliance}></TableCell>
                          <TableCell className={classes.titleHeadCompliance}></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                    {/* ======================== BODY ========================== */}
                    <div className="supplier-wrapper">
                      {/* ======================== START 1 RECORD ========================== */}
                      {uniqueSupplierList
                        .sort((a, b) => a.CD_CUST.localeCompare(b.CD_CUST) || a.CD_HAT.localeCompare(b.CD_HAT))
                        .map((el, index) => (
                          <div key={index}>
                            <div className="supplier-row">
                              {/* ======================== HEADER START  ======================== */}
                              <div className="supplier-container">
                                <div className="supplier-header customer-code supplier-groupTitle-size">
                                  <span className="supplier-groupTitle">{t('compliance.lblCustCode')} </span>
                                  <span>{el.CD_CUST}：</span>
                                  <span>{el.NM_CUST}</span>
                                </div>
                                <div className="supplier-header supplier-code supplier-groupTitle-size">
                                  <span className="supplier-groupTitle">{t('compliance.lblSupplierCode')} </span>
                                  <span>{el.CD_HAT}：</span>
                                  <span>{el.NM_HAT}</span>
                                </div>
                                <div className="supplier-header telephone-number">
                                  <span className="supplier-groupTitle">{t('compliance.lblTelephoneNumber')} </span>
                                  <span>{el.NO_TEL}</span>
                                </div>
                                <div className="supplier-header preFecture-code">
                                  <span className="supplier-groupTitle">{t('compliance.lblPrefectureCode')} </span>
                                  <span>{el.CD_PREF}</span>
                                </div>
                              </div>
                              {/* ======================== HEADER END  ========================*/}
                              {/* ======================== LINE START ======================== */}

                              <div style={{ width: '100%' }}>
                                <hr style={{ width: '100%', border: '2px solid rgb(85, 130, 50)' }} />
                              </div>
                              {/* ======================== LINE END ======================== */}

                              {/*======================== ONE ROW START ========================*/}
                              {listData
                                .filter((arr) => arr.CD_HAT === el.CD_HAT)
                                .sort((a, b) => a.CD_SYO.localeCompare(b.CD_SYO))
                                .map((renderData, renderDataIndex) => (
                                  <div
                                    className={`${
                                      contaminationDisplay === FLAG_BOOLEAN.FALSE
                                        ? renderDataIndex % 2 === 0
                                          ? 'compliance-row'
                                          : 'compliance-row color-blue'
                                        : renderDataIndex % 2 === 0
                                        ? 'compliance-row compliance-max-height'
                                        : 'compliance-row compliance-max-height color-blue'
                                    }`}
                                    key={renderDataIndex}
                                  >
                                    {/*======================== CONFIRM CHECK BOX START ========================*/}
                                    <div className="confirm-checkbox">
                                      <div className="checkbox-container">
                                        <CustomCheckbox
                                          value={dataCompliance[findIndexData(renderData)]?.KB_CNFM === '1' || false}
                                          onChange={(e) => handleUpdateCompliance('KB_CNFM', e, renderData)}
                                          className={`${
                                            sysInfo.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.FALSE
                                              ? 'paddingTop15 confirm-checkbox checkbox'
                                              : 'confirm-checkbox checkbox'
                                          }`}
                                        />
                                      </div>
                                      <div
                                        className={`confirm-date ${
                                          sysInfo?.MP_COMP?.KB_CONTAMI === FLAG_BOOLEAN.TRUE ? 'paddingBottom15' : ''
                                        }`}
                                      >
                                        <div>
                                          {formatDateWithPadding(renderData?.DT_CNFM, 'yyyy-MM-dd') || (
                                            <div style={{ height: '16px' }}></div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {/*======================== CONFIRM CHECK BOX END ======================== */}
                                    {/* ======================== TRIPPLE ROW START ======================== */}
                                    <Table aria-label="common table" className={classes.testedTable}>
                                      <colgroup>
                                        <col style={{ width: '15%' }} className="col-1-full-width" />
                                        <col style={{ width: '66%' }} className="col-2-full-width" />
                                        <col style={{ width: '9%' }} className="col-3-full-width" />
                                        <col style={{ width: '10%' }} className="col-4-full-width" />
                                      </colgroup>
                                      <TableBody>
                                        {/*========================  ROW 1 ========================  */}
                                        <TableRow
                                          className={`${
                                            contaminationDisplay === FLAG_BOOLEAN.FALSE
                                              ? classes.complianceRow
                                              : classes.complianceThirdRow
                                          }`}
                                        >
                                          <TableCell
                                            className={`${classes.complianceCell} ${classes.complianceCellBorder}`}
                                          >
                                            {' '}
                                            <div className="compliance-cell   product-code-cell">
                                              {renderData.CD_SYO}
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.complianceCell} ${classes.complianceCellBorder}`}
                                          >
                                            {' '}
                                            <div className="compliance-cell  ">{renderData.NM_SYO}</div>
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.complianceCell} ${classes.complianceCellBorder}`}
                                          >
                                            {' '}
                                            <div className="compliance-cell  ">{renderData.CD_CAT}</div>
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.complianceCell} ${classes.complianceCellBorder}`}
                                          >
                                            {' '}
                                            <div className="compliance-cell   text-red text-align-last-cell">
                                              {formatDateWithPadding(renderData?.DT_APPR, 'yyyy-MM-dd') || (
                                                <div style={{ height: '16px' }}></div>
                                              )}
                                            </div>
                                          </TableCell>
                                        </TableRow>
                                        {/* ======================== ROW 2 ======================== */}

                                        <TableRow
                                          className={`${
                                            contaminationDisplay === FLAG_BOOLEAN.FALSE
                                              ? classes.complianceRow
                                              : classes.complianceThirdRow
                                          }`}
                                        >
                                          <TableCell className={classes.complianceCell} rowSpan={2}>
                                            <div className="compliance-cell double-radio-checkbox">
                                              <div className="double-radio-checkbox-container">
                                                <div className="first-radio-checkbox">
                                                  {sysInfo?.MP_COMP?.KB_SYOHYO === FLAG_BOOLEAN.TRUE &&
                                                  renderData?.KB_CMPL === FLAG_BOOLEAN.TRUE ? (
                                                    <RedRadio />
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                                <div className="second-radio-checkbox">
                                                  {sysInfo?.MP_COMP?.KB_KANNAI === FLAG_BOOLEAN.TRUE &&
                                                  renderData?.KB_KINFO === FLAG_BOOLEAN.TRUE ? (
                                                    <RedRadio />
                                                  ) : (
                                                    ''
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.complianceCell} ${
                                              contaminationDisplay === FLAG_BOOLEAN.FALSE
                                                ? ''
                                                : classes.complianceCellBorder
                                            }`}
                                            colSpan={3}
                                          >
                                            <div className={`compliance-cell`}>{renderAllrgenData(renderData)}</div>
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.complianceCell} ${
                                              contaminationDisplay === FLAG_BOOLEAN.FALSE
                                                ? ''
                                                : classes.complianceCellBorder
                                            }`}
                                          >
                                            <div className={'compliance-cell'}></div>
                                          </TableCell>
                                          <TableCell
                                            className={`${classes.complianceCell} ${
                                              contaminationDisplay === FLAG_BOOLEAN.FALSE
                                                ? ''
                                                : classes.complianceCellBorder
                                            }`}
                                          >
                                            <div className={'compliance-cell'}></div>
                                          </TableCell>
                                        </TableRow>
                                        {/*========================  ROW 3 ========================*/}
                                        {contaminationDisplay === FLAG_BOOLEAN.FALSE ? (
                                          ''
                                        ) : (
                                          <TableRow
                                            className={`${
                                              contaminationDisplay === FLAG_BOOLEAN.FALSE
                                                ? classes.complianceRow
                                                : classes.complianceThirdRow
                                            }`}
                                          >
                                            {/* <TableCell className={classes.complianceCell}></TableCell> */}
                                            <TableCell
                                              className={`${classes.complianceCell} ${
                                                renderData.KB_CNTMSET === KONTAMI_CLASSFICATION_CODE.NO_KONTAMI
                                                  ? classes.textRed
                                                  : ''
                                              }`}
                                              colSpan={3}
                                            >
                                              {renderKontamiData(renderData)}
                                            </TableCell>
                                            <TableCell className={classes.complianceCell}></TableCell>
                                            <TableCell className={classes.complianceCell}></TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                    {/*======================== TRIPPLE ROW END ======================== */}
                                  </div>
                                ))}

                              {/*======================== ONE ROW END ======================== */}
                            </div>
                            {index === uniqueSupplierList.length - 1 ? (
                              <div className="buttonSpaceCompliance">
                                <Button
                                  className="button submit-button button-compliance"
                                  type="submit"
                                  onClick={handleSubmit}
                                  variant="contained"
                                >
                                  {t('compliance.lblButtonUpdate')}
                                </Button>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ))}

                      {/* Button Update */}
                      <div className="emptyHeightElement"></div>

                      {/* <Button className="button submit-button" type="submit" variant="contained">
                        {t('maintenanceCreateEdit.btnSubmit')}
                      </Button> */}

                      {/* ======================== END 1 RECORD ========================== */}
                    </div>
                  </div>
                </div>
              </TableContainer>
            </Grid>
          </div>
        </div>
      )}
    </div>
  )
}

export default Compliance
